<template>
  <div class="row mt-3">
    <div class="col mb-4">
      <div class="card">
        <div class="card-body d-block p-1 text-start card-number-list">
          <span
            v-for="(number, index) in data"
            :key="index"
            class="badge bg-secondary m-1"
          >
            {{ number }}
            <a @click="handleRemoveNumber(index)" class="pointer">
              <img src="~@/assets/x-icon.svg" />
            </a>
          </span>
        </div>
      </div>
      <div v-if="isErrorText" class="text-danger">จำเป็นต้องใส่ตัวเลขอย่างน้อย 1 ชุด</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["betNumber", "replaceNumberList", "isErrorText"],
  computed: {
    data() {
      return this.betNumber;
    },
  },
  methods: {
    handleRemoveNumber(index) {
      this.data.splice(index,1)
      this.replaceNumberList(this.data);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-number-list {
  height: 130px;
  overflow-y: scroll;
}
.badge {
  font-family: "Prompt";
  font-size: 16px;
  font-weight: 400;
}
.pointer {
  cursor: pointer;
}
</style>