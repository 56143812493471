<template>
  <select
    class="form-select"
    :disabled="disabled"
    v-model="list"
    v-on:change="listSelected"
  >
    <option disabled value="" selected>เลือกหวย</option>
    <option v-for="list in lists" :key="list._id" :value="list">
      {{ list.name }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      list: null,
    };
  },
  name: "List",
  methods: {
    listSelected() {
      var listSelected = this.list;
      this.$emit("onListSelect", listSelected);
    },
  },
  watch: {
    lists() {
      this.list = null;
    },
  },
  props: {
    lists: {
      type: Object,
      required: true,
    },
    disabled: {},
  },
};
</script>
