export default {
  state: {
    isLoading: false,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    openLoading(state) {
      state.isLoading = true;
    },
    closeLoading(state) {
      state.isLoading = false;
    },
  },
  actions: {
    loadingeOpen: ({ commit }) => {
      commit("openLoading");
    },
    loadingeClose: ({ commit }) => {
      commit("closeLoading");
    },
  },
};
