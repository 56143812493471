<template>
  <select
    class="form-select"
    :disabled="disabled"
    v-model="round"
    v-on:change="roundSelected"
  >
    <option disabled value="" selected>เลือกรอบ</option>
    <option v-for="round in rounds" :key="round._id" :value="round">
      {{ round.round_name }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      round: null,
    };
  },
  name: "Round",
  methods: {
    roundSelected() {
      var roundSelected = this.round;
      this.$emit("onRoundSelect", roundSelected);
    },
  },
  watch: {
    rounds() {
      this.round = null;
    },
  },
  props: {
    rounds: {
      type: Object,
      required: true,
    },
    disabled: {},
  },
};
</script>
