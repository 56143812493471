<template>
  <div class="background">
    <div class="header">
      <img
        src="~@/assets/logo.svg"
        class="img-fluid"
        alt="PP Lotto Agent-CMS"
      />
      <span class="title-header">Agent-CMS</span>
    </div>
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.background {
  background: url("~@/assets/bg-login.svg") no-repeat center center fixed;
  width: 100vw;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.header {
  padding: 30px;
  .title-header {
    color: $white;
    font-size: 24px;
    position: relative;
    top: 6px;
  }
}
</style>
