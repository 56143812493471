<template>
  <div>
    <div
      ref="modal"
      class="modal fade in"
      :class="[{ show: isShow, 'd-block': isShow }, type]"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <h5 class="modal-title m-4">
              <img :src="imgIcon" />
              {{ title }}
            </h5>
            <p class="modal-description m-4">{{ description }}</p>
            <button class="close" @click="modalClose">ปิด</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShow" class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
export default {
  watch: {
    isShow(newVal) {
      const body = document.querySelector("body");
      if (newVal) {
        body.classList.add("modal-open");
      } else {
        body.classList.remove("modal-open");
      }
    },
  },
  computed: {
    imgIcon() {
      if (this.$store.getters.responseType) {
        return require(`../assets/response-${this.$store.getters.responseType}-icon.svg`);
      }
      return null;
    },
    isShow() {
      return this.$store.getters.responseShow;
    },
    type() {
      // return `modal-${this.$store.getters.responseType}`;
      return "modal-" + this.$store.getters.responseType;
    },
    title() {
      return this.$store.getters.responseTitle;
    },
    description() {
      return this.$store.getters.responseDescription;
    },
  },
  methods: {
    modalClose() {
      this.$store.dispatch("responseClose");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-dialog {
  color: $white;
  border-radius: 24.5865px;
  .modal-content {
    border-radius: 24.5865px;
    .modal-title {
      font-size: 34px;
      font-weight: 600;
    }
    .modal-description {
      font-size: 26px;
      font-weight: 400;
    }
    .close {
      background-color: $white;
      color: $black;
      float: right;
      border-radius: 12.5px;
      border: none;
      padding: 10px 30px;
      margin: 10px;
    }
  }
}

.modal-error {
  .modal-content {
    background-color: rgba(255, 88, 98, 0.95);
  }
}
.modal-success {
  .modal-content {
    background-color: $submit-color;
  }
}
</style>
