<template>
  <div style="min-width: 1600px">
    <nav class="navbar navbar-light bg-primary">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="~@/assets/logo.svg" height="40" alt="PP Lotto Agent-CMS" />
          <span class="title-header">Agent-CMS</span>
        </a>
        <div class="d-flex">
          <div class="dropdown">
            <button
              class="btn btn-default btn-profile dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="display-name">
                <img src="~@/assets/user-icon-circle.svg" /> {{displayName}}
              </span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a class="dropdown-item" href="#" @click="logout">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <slot />
  </div>
</template>
<script>
export default {
  computed: {
    displayName() {
      return this.$store.getters.getDisplayName;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
<style lang="scss" scoped>
.title-header {
  color: $white;
  font-size: 24px;
}
.btn-profile {
  color: $white;
  .display-name {
    margin: 0px;
  }
}
</style>
