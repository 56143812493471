import axios from "axios";
import store from "../store"; 
import router from "../router";
axios.defaults.baseURL = process.env.VUE_APP_REQUEST_URL;
console.log(process.env.NODE_ENV);

const getToken = function () {
  return localStorage.getItem("token");
};

export async function request(dataRequest, auth = false) {
  store.dispatch("loadingeOpen");
  let headers = {
    "Content-Type": "application/json",
  };
  if (auth) {
    const headerAuth = {
      Authorization: "Bearer " + getToken(),
    };

    headers = {
      ...headers,
      ...headerAuth,
    };
  }
  try {
    const { status, data } = await axios({
      method: "post",
      url: "/",
      data: dataRequest,
      headers,
    });
    store.dispatch("loadingeClose");
    if (status !== 200) {
      throw {
        type: "http",
        code: status,
        message: "Http rsponse code = " + status,
      };
    } else if (data.type === "empty") {
      store.dispatch("responseOpen", {
        type: "error",
        title: "Response Error",
        description: "กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ",
      });
    } else if (data.type == "exception") {
      store.dispatch("responseOpen", {
        type: "error",
        title: data.exception.subject,
        description: data.exception.description,
      });
    } else if (data.type === "result") {
      return data.result;
    }
  } catch (error) {
    const { status, data } = error.response;
    const { subject, description } = data.exception;
    store.dispatch("loadingeClose");
    store.dispatch("responseOpen", {
      type: "error",
      title: subject,
      description: description,
    });
    if (status === 401) {
      router.push("/login");
    }
    excecptionHandler(error);
  }
}

function excecptionHandler(error) {
  console.log("error", error);
}
