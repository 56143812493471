import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@popperjs/core";
import "bootstrap";
import "./assets/scss/app.scss";
import DefaultLayout from "./layouts/Default.vue";
import LoginLayout from "./layouts/Login.vue";
import BlankLayout from "./layouts/Blank.vue";

createApp(App)
  .component("default-layout", DefaultLayout)
  .component("login-layout", LoginLayout)
  .component("blank-layout", BlankLayout)
  .use(store)
  .use(router)
  .mount("#app");
