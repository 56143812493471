<template>
  <div>
    <div class="row g-3 align-items-center">
      <div class="col-5">
        <label for="inputMaxBet" class="col-form-label">
          <img
            v-if="!errors && (data ? true : false)"
            src="~@/assets/check-icon.svg"
          />
          แทงสูงสุดต่อครั้ง
        </label>
      </div>
      <div class="col-5">
        <input
          type="text"
          id="inputMaxBet"
          class="form-control text-end"
          aria-describedby="maxBetHelpInline"
          autocomplete="off"
          v-model="data"
          :class="{ 'is-invalid': errors }"
          :placeHolder="placeHolder"
        />
      </div>
      <div class="col-1">
        <span id="maxBetHelpInline"> บาท </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="offset-5 col-7 text-start mb-2">
        <span v-if="errors" role="alert" class="text-danger text-start">
          <strong>{{ errors }}</strong>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import validate from "@/utils/validate";
export default {
  props: {
    placeHolder: {
      type: String,
      default: null,
    },
    defaultData: {
      type: String,
      default: null,
    },
    handleAction: { type: Function },
    handleError: { type: Function },
    countReset: { type: Number },
  },
  data() {
    return {
      data: null,
      errors: null,
    };
  },
  watch: {
    countReset() {
      this.errors = null;
      this.data = null;
    },
    defaultData(newVal) {
      if (newVal) {
        this.data = newVal;
      }
    },
    data(newVal) {
      this.handleError(false, "maxBet");
      let errors = null;
      if (newVal) {
        if (!validate.price(newVal)) {
          this.handleError(true, "maxBet");
          errors = "จำเป็นต้องใส่เป็นราคาเท่านั้น";
        }
      }
      this.handleAction(newVal);
      this.errors = errors;
    },
  },
};
</script>
