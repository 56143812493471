<template>
  <select
    class="form-select"
    :disabled="disabled"
    v-model="opt"
    v-on:change="optSelected"
  >
    <option disabled value="" selected>เลือกรูปแบบเลข</option>
    <option v-for="opt in opts" :key="opt.id" :value="opt">
      {{ opt.name }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      opt: null,
    };
  },
  name: "BetOption",
  methods: {
    optSelected() {
      var optSelected = this.opt;
      this.$emit("onOptionSelect", optSelected);
    },
  },
  watch: {
    opts() {
      this.opt = null;
    },
  },
  props: {
    opts: {
      type: Object,
      required: true,
    },
    disabled: {},
  },
};
</script>
