<template>
  <div>
    <div
      ref="modal"
      class="modal fade in modal-form-check-number"
      :class="{ show: isShow, 'd-block': isShow }"
      tabindex="-1"
      role="dialog"
      id="modal-form-check-number"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header d-block">
            <h5 class="modal-title">
              <Title
                :listName="listName"
                :roundName="roundName"
                :optionName="optionName"
              />
            </h5>
          </div>
          <div class="modal-body text-center">
            <div class="row">
              <div class="col-6">
                <p class="text-start text-title">Step อัตราจ่าย</p>
                <Table
                  :dataRewards="data.reward_step"
                  :totalBet="data.total_bet"
                />
              </div>
              <div class="col-6">
                <div class="mb-4 text-end">
                  <div class="input-group justify-content-end">
                    <input
                      type="text"
                      class="form-control input-search"
                      id="checkNumber"
                      aria-describedby="inputCheckNumber"
                      autocomplete="off"
                      v-model="number"
                    />
                    <button
                      class="btn btn-search"
                      type="button"
                      id="inputCheckNumber"
                      @click="handleQueryData"
                    >
                      <img src="~@/assets/search-icon.svg" width="21" />
                    </button>
                  </div>
                  <div class="text-danger mb-2" v-if="errors">
                    <strong>{{ errors }}</strong>
                  </div>
                </div>
                <DataDetail
                  :title="'ยอดแทงรวมทั้งหมด'"
                  :description="data.total_bet"
                />
                <DataDetail
                  :title="'แทงได้ต่ำสุดต่อครั้ง'"
                  :description="data.bet_min"
                />
                <DataDetail
                  :title="'แทงได้สูงสุดต่อครั้ง'"
                  :description="data.bet_max"
                />
                <DataDetail
                  :title="'แทงได้สูงสุดต่อคน'"
                  :description="data.max_per_number"
                />
                <DataDetail
                  :title="'อัตราจ่ายขณะนี้'"
                  :description="currentBet"
                />
              </div>
            </div>
            <div class="d-float">
              <button
                class="btn btn-secondary mx-2 float-end btn-form-close"
                @click="modalClose"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShow" class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
import Title from "./Title.vue";
import Table from "./Table.vue";
import DataDetail from "./DataDetail.vue";
import * as DashboardApi from "@/utils/dashboardApi.js";
import validate from "@/utils/validate";

export default {
  components: { Title, Table, DataDetail },
  props: ["isShow", "list", "round", "option", "defaultNumber", "modalClose"],
  data() {
    return {
      number: null,
      data: [],
    };
  },
  watch: {
    defaultNumber(newVal) {
      if(newVal) {
        this.number = newVal;
        this.handleQueryData()
      }else{
        this.handleResetData()
      }
      return null;
    },
    isShow(newVal) {
      const body = document.getElementById("modal-form-check-number");
      if (newVal) {
        body.classList.add("modal-open");
      } else {
        body.classList.remove("modal-open");
      }
    },
  },
  computed: {
    errors() {
      let errors = null;
      if (this.number) {
        if (
          validate.number(this.number) &&
          validate.length(this.number, this.option.digit)
        ) {
          errors = null;
        } else {
          errors = `ต้องกรอกตัวเลข ${this.option.digit} หลัก เท่านั้น`;
        }
      }
      return errors;
    },
    listName() {
      return this.list ? this.list.name : null;
    },
    roundName() {
      return this.round ? this.round.round_name : null;
    },
    optionName() {
      return this.option ? this.option.name : null;
    },
    currentBet() {
      let index = null;
      if (this.data.reward_step) {
        for (let i = 0; i < this.data.reward_step.length; i++) {
          if (this.data.reward_step[i].amt <= this.data.total_bet) {
            index = i;
          } else {
            break;
          }
        }
        return this.data.reward_step[index].reward;
      } else {
        return null;
      }
    },
  },
  methods: {
    async handleQueryData() {
      if (this.number && this.errors === null) {
        this.data = await DashboardApi.getNumConfigInfo(
          this.round._id,
          this.option.id,
          this.number
        );
      }
    },
    handleResetData() {
      this.number = null;
      this.data = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-form-check-number {
  .text-title {
    font-weight: 600;
    color: $black;
    font-size: 25px;
  }
  .modal-content {
    border-radius: 25px;
    .modal-header {
      border-radius: 25px 25px 0 0;
      color: $white;
      background-color: $primary;
    }
    .input-number {
      width: 100px;
    }
  }
  .input-search {
    max-width: 100px;
    background-color: #efefef;
    border: none;
    border-radius: 13px 0 0 13px;
  }
  .btn-search {
    background-color: #efefef;
    border: none;
    border-radius: 0 13px 13px 0;
  }
  .btn-form-close {
    padding: 9px 50px;
    border-radius: 12px;
  }
  .text-danger {
    right: 20px;
  }
}
</style>
