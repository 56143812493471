<template>
  <div class="form-check form-switch">
    <input
      class="form-check-input input-status"
      type="checkbox"
      v-model="data"
    />
  </div>
</template>
<script>
export default {
  props: {
    defaultData: {
      type: String,
      default: null,
    },
    handleAction: { type: Function },
  },
  data() {
    return {
      data: null,
    };
  },
  watch: {
    defaultData(newVal) {
      if (newVal) {
        this.data = newVal;
      }
    },
    data(newVal) {
      this.handleAction(newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-check-input.input-status {
  width: 50px;
  height: 30px;
  &:checked {
    background-color: $light-green;
    border-color: $light-green;
  }
}
</style>
