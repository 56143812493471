<template>
  <div>
    <table :class="['v-datatable-light', css.table]">
      <thead :class="css.thead" :style="theadStyle">
        <tr :class="css.theadTr">
          <th
            v-for="(item, columnIndex) in headers"
            :key="item.label"
            :class="
              headerItemClass(item, [
                css.theadTh,
                `header-column-${columnIndex}`,
              ])
            "
            :style="getColumnWidth(item)"
            :width="item.width"
          >
            <!-- header free text -->
            <div
              v-if="!isFieldSpecial(item.name) && !item.customHeader"
              :class="[css.thWrapper, `header-column-${columnIndex}`]"
              @click="orderBy(item.name)"
            >
              {{ item.label }}
              <div
                v-if="item.sortable"
                :class="arrowsWrapper(item.name, css.arrowsWrapper)"
              >
                <div v-if="showOrderArrow(item, 'desc')" :class="css.arrowUp" />
                <div
                  v-if="showOrderArrow(item, 'asc')"
                  :class="css.arrowDown"
                />
              </div>
            </div>
            <!-- end header free text -->
            <!-- header custom header -->
            <div
              v-if="!isFieldSpecial(item.name) && item.customHeader"
              :class="[css.thWrapper, `header-column-${columnIndex}`]"
              @click="orderBy(item.name)"
            >
              <slot
                v-if="item.customHeader"
                :header-data="item"
                :name="customHeaderName(item)"
              />
              <div
                v-if="item.sortable"
                :class="arrowsWrapper(item.name, css.arrowsWrapper)"
              >
                <div v-if="showOrderArrow(item, 'desc')" :class="css.arrowUp" />
                <div
                  v-if="showOrderArrow(item, 'asc')"
                  :class="css.arrowDown"
                />
              </div>
            </div>
            <!-- end header custom header -->
            <!-- especial field -->
            <div
              v-if="
                isFieldSpecial(item.name) &&
                extractArgs(item.name) === 'checkboxes'
              "
              :class="css.thWrapperCheckboxes"
            >
              <input
                type="checkbox"
                :class="css.checkboxHeader"
                :checked="checkedAll"
                @click="checkAll"
              />
            </div>
            <!-- end especial field -->
          </th>
        </tr>
      </thead>
      <tbody :class="css.tbody" :style="tbodyStyle">
        <!-- spinner slot -->
        <template v-if="isLoading">
          <tr :class="css.tbodyTrSpinner">
            <td :colspan="headers.length" :class="css.tbodyTdSpinner">
              <slot name="spinner" />
            </td>
          </tr>
        </template>
        <!-- end spinner slot -->
        <!-- table rows -->
        <template v-else-if="configArr.length">
          <tr
            v-for="(item, index) in sortedData"
            :key="index"
            :class="[css.tbodyTr, `row-${index}`]"
          >
            <td
              v-for="(key, columnIndex) in headers"
              :key="`${index}-${key.name}`"
              :class="[
                css.tbodyTd,
                `column-${columnIndex}`,
                { 'bg-table-gray': columnIndex % 2 !== 0 },
              ]"
              :style="getColumnWidth(key)"
            >
              <template v-if="columnIndex === 0">{{ item.uniqid }}</template>
              <template v-else-if="columnIndex === 1">
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(num, idx) in item.for_numbers"
                    :key="idx"
                    @click="checkNumberOpen(num)"
                  >
                    <span
                      class="pointer hover-highlight"
                      v-if="idx === item.for_numbers.length - 1"
                    >
                      {{ num }}
                    </span>
                    <span
                      class="pointer hover-highlight"
                      v-else-if="idx < item.for_numbers.length - 1"
                    >
                      {{ num }} 
                    </span>
                    <span v-if="idx < item.for_numbers.length - 1">,</span>
                  </div>
                </div>
              </template>
              <template v-else-if="columnIndex === 2">
                <div v-if="item.bet_limit.minBet && item.bet_limit.maxBet">
                  {{ filters.number(item.bet_limit.minBet) + " - " + filters.number(item.bet_limit.maxBet) }}
                </div>
                <div
                  v-else-if="!item.bet_limit.minBet && item.bet_limit.maxBet"
                >
                  {{ "ไม่ระบุ - " + filters.number(item.bet_limit.maxBet) }}
                </div>
                <div
                  v-else-if="item.bet_limit.minBet && !item.bet_limit.maxBet"
                >
                  {{ filters.number(item.bet_limit.minBet) + " - ไม่ระบุ" }}
                </div>
                <div
                  v-else-if="!item.bet_limit.minBet && !item.bet_limit.maxBet"
                >
                  {{ "ไม่ระบุ - ไม่ระบุ" }}
                </div>
              </template>
              <template v-else-if="columnIndex === 3">
                <div v-if="item.max_per_number">{{ filters.number(item.max_per_number) }}</div>
                <div v-else-if="!item.max_per_number">ไม่ระบุ</div>
              </template>
              <template v-else-if="columnIndex === 4">
                <div
                  v-if="
                    item[key.name][0].reward === item[key.name][1].reward &&
                    item[key.name].length === 2
                  "
                >
                  <div class="badge-reward-close">ปิดรับ</div>
                </div>
                <div v-else class="badge-reward">
                  <div
                    class="badge-reward-item row"
                    v-for="(rewards, index) in item[key.name]"
                    :key="index"
                  >
                    <div class="col-5 m-0 p-0 text-end" v-if="rewards.amt != 0">
                      {{ filters.number(rewards.amt) }}
                    </div>
                    <div class="col-5 m-0 p-0 text-end" v-else>
                      0
                    </div>
                    <div class="col-2 m-0 p-0 text-center">=></div>
                    <div class="col-5 m-0 p-0 text-end" v-if="rewards.reward != 0">
                      {{ filters.number(rewards.reward) }}
                    </div>
                    <div class="col-5 m-0 p-0 text-end" v-else>
                      0
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="columnIndex === 5">
                <div v-if="item[key.name]">
                  <span class="badge bg-success">เปิด</span>
                </div>
                <div v-else-if="!item[key.name]">
                  <span class="badge bg-danger">ปิด</span>
                </div>
              </template>
              <template v-else-if="columnIndex === 6">
                <UpdateNumber
                  isActiveButton="true"
                  :list="list"
                  :round="round"
                  :option="option"
                  :uniqid="item.uniqid"
                  :handleData="handleAction"
                />
              </template>
            </td>
          </tr>
        </template>
        <!-- end table rows -->
        <!-- table not found row -->
        <template v-else>
          <tr :class="css.notFoundTr">
            <td :colspan="headers.length" :class="css.notFoundTd">
              {{ notFoundMessage }}
            </td>
          </tr>
        </template>
        <!-- end table not found row -->
      </tbody>
      <tfoot v-if="hasSlots" :class="css.tfoot">
        <tr :class="css.tfootTr">
          <th :colspan="headers.length" :class="css.tfootTd">
            <div :class="css.footer">
              <slot name="ItemsPerPage" />
              <slot name="pagination" />
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import UpdateNumber from "../ManageNumberForm/Update.vue";
import filters from "../../filters";

export default {
  setup() {
    return {
      filters,
    };
  },
  name: "DataTable",
  components: {
    UpdateNumber,
  },
  props: {
    handleCheckNumberOpen: {
      type: Function,
      required: true,
    },
    headerFields: {
      type: Array,
      required: true,
    },
    configArr: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    sortField: {
      type: String,
      default: null,
    },
    sort: {
      type: String,
      default: null,
    },
    notFoundMsg: {
      type: String,
      default: null,
    },
    trackBy: {
      type: String,
      default: "id",
    },
    css: {
      type: Object,
      default: () => ({
        table: "",
        thead: "thead",
        theadTr: "thead-tr",
        theadTh: "thead-th",
        tbody: "tbody",
        tbodyTr: "tbody-tr",
        tbodyTrSpinner: "tbody-tr-spinner",
        tbodyTd: "tbody-td",
        tbodyTdSpinner: "tbody-td-spinner",
        tfoot: "tfoot",
        tfootTd: "tfoot-td",
        tfootTr: "tfoot-tr",
        footer: "footer",
        thWrapper: "th-wrapper",
        thWrapperCheckboxes: "th-wrapper-checkboxes",
        arrowsWrapper: "arrows-wrapper",
        arrowUp: "arrow-up",
        arrowDown: "arrow-down",
        checkboxHeader: "checkbox-header",
        checkbox: "checkbox",
        notFoundTr: "not-found-tr",
        notFoundTd: "not-found-td",
      }),
    },
    tableHeight: {
      type: String,
      default: null,
    },
    defaultColumnWidth: {
      type: String,
      default: "150px",
    },
    onlyShowOrderedArrow: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Object,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    handleAction: {
      type: Function,
      required: true,
    },
  },  
  created(){
    this.sortId()
  },
  data: function () {
    return {
      sortedField: this.sortField,
      sortedDir: this.sort,
      notFoundMessage: this.notFoundMsg,
      loading: this.isLoading,
      checkedAll: false,
      itemsChecked: [],
      sortedData: [],
    };
  },
  computed: {
    hasSlots: function () {
      return (
        this.$slots.pagination !== undefined ||
        this.$slots.ItemsPerPage !== undefined
      );
    },

    headers: function () {
      if (
        this.headerFields &&
        this.headerFields.constructor === Array &&
        this.headerFields.length
      ) {
        return Object.keys(this.headerFields).map((key) => {
          const field = this.headerFields[key];
          if (typeof field === "string") {
            return { label: field, name: field };
          }
          return field;
        });
      }
      return [];
    },
    tbodyStyle: function () {
      if (this.tableHeight) {
        return {
          height: this.tableHeight,
          display: "block",
          overflowX: "auto",
        };
      }
      return null;
    },
    theadStyle: function () {
      return this.tableHeight ? { display: "block" } : null;
    },
  },
  watch: {
    configArr: function(){
      this.sortId()
    }
  },
  methods: {
    arrowsWrapper: function (field, className) {
      if (this.sortedField === field && this.sortedDir) {
        return `${className} centralized`;
      }
      return className;
    },

    updateData: function () {
      const params = {
        sortField: this.sortedField,
        sort: this.sortedDir,
      };

      this.$emit("on-update", params);
    },

    orderBy: function (field) {
      if (this.isFieldSortable(field)) {
        if (this.sortedField === field) {
          this.sortedDir = this.sortedDir === "asc" ? "desc" : "asc";
        } else {
          this.sortedDir = "desc";
          this.sortedField = field;
        }
        this.updateData();
      }
    },

    checkAll: function () {
      this.checkedAll = !this.checkedAll;
      if (this.checkedAll) {
        this.itemsChecked = this.configArr;
      } else {
        this.itemsChecked = [];
      }
      this.$emit("on-check-all", this.itemsChecked);
    },

    checkItem: function (item) {
      const found = this.itemsChecked.find(
        (itemChecked) => itemChecked[this.trackBy] === item[this.trackBy]
      );
      if (found) {
        this.itemsChecked = this.itemsChecked.filter(
          (itemChecked) => itemChecked[this.trackBy] !== item[this.trackBy]
        );
        this.$emit("on-unchecked-item", item);
      } else {
        this.itemsChecked = [...this.itemsChecked, item];
        this.$emit("on-checked-item", item);
      }
    },
    isCheckedItem: function (item) {
      return !!this.itemsChecked.find(
        (itemChecked) => itemChecked[this.trackBy] === item[this.trackBy]
      );
    },

    isFieldSortable: function (field) {
      const foundHeader = this.headerFields.find((item) => item.name === field);
      return foundHeader && foundHeader.sortable;
    },

    headerItemClass: function (item, className = []) {
      const classes = className.join(" ");
      return item && item.sortable ? classes : `${classes} no-sortable`;
    },

    isFieldSpecial: (field) => field.indexOf("__") > -1,

    extractArgs: (string) => string.split(":")[1],

    extractActionID: (string) => {
      const list = string.split(":");
      return list.length === 3 ? list[2] : "actions";
    },

    getColumnWidth: function (item) {
      if (this.tableHeight) {
        if (item.name === "__slot:checkboxes") {
          return { width: "50px" };
        } else if (item.name === "nums") {
          return { width: "50px" };
        }
        return { width: item.width || this.defaultColumnWidth };
      }
    },

    customElementName: ({ customElement, name }) =>
      typeof customElement === "string" ? customElement : name,

    customHeaderName: ({ customHeader, name }) =>
      typeof customHeader === "string" ? customHeader : `${name}:header`,

    showOrderArrow: function (item, sortDir) {
      if (this.onlyShowOrderedArrow) {
        return this.sortedField === item.name && this.sortedDir !== sortDir;
      }
      return (
        this.sortedField !== item.name ||
        (this.sortedField === item.name && this.sortedDir === sortDir)
      );
    },
    checkNumberOpen(number) {
      this.handleCheckNumberOpen(number);
    },
    sortId(){
      const arr = this.configArr
      this.sortedData = arr.sort((a,b) => b['uniqid'] - a['uniqid'])
    }
  },
};
</script>
<style lang="scss" scoped>
.table-style {
  font-size: 24px;
  color: #2e2e2e;
  text-align: center;
  border-radius: 18.91px;
  overflow:hidden;
}
.bg-table-gray {
  background-color: #f0f0f0;
}

.thead {
  background-color: #2a40ff;
  color: #f8f8ff;
  text-align: center;
}

.btn-ligth-green {
  background: #9dfd8d !important;
  border-color: #9dfd8d !important;
  border-radius: 11.7692px;
  margin-bottom: 10px;
}

.btn-ligth-red {
  background-color: #ff6973 !important;
  border-color: #ff6973 !important;
  border-radius: 11.7692px;
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.badge-reward {
  margin: 0 20px;
  font-size: 18px;
  font-weight: 500;
  div {
    margin: 5px 0;
  }
  .badge-reward-item {
    background-color: #9dfd8d;
    color: $black;
    border-radius: 12px;
    padding: 2px 10px;
  }
}
.badge-reward-close {
  margin: 0 20px;
  background-color: #ffbcbc;
  color: $black;
  font-size: 18px;
  border-radius: 12px;
  padding: 2px 10px;
}

.hover-highlight:hover {
  color: $primary;
}
</style>
