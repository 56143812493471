export const number = (value) => {
  return /^\d+$/.test(value);
};
export const price = (value) => {
  return /^[1-9][0-9]*$/.test(value) || value == 0;
};
export const oneDigit = (value) => {
  return /^[0-9]+\.[0-9]{1}$|^[1-9][0-9]*$/.test(value) || value == 0;
};

export const length = (value, length) => {
  return value.length === length;
};

export const required = (value) => {
  return value ? true : false;
};

export default {
  number,
  length,
  price,
  required,
  oneDigit,
};
