<template>
  <div>
    <button
      class="btn btn-dark-brown text-white"
      @click="modalOpen"
      :disabled="!isActiveButton === 'ok'"
    >
      <img src="~@/assets/fa-clipboard-list.svg" />
      ตรวจสอบอัตราจ่ายแต่ละเลข
    </button>
    <Modal
      :isShow="isShow"
      :list="list"
      :round="round"
      :option="option"
      :modalClose="modalClose"
      :defaultNumber="numberData"
    />
  </div>
</template>
<script>
import Modal from "./Modal.vue";

export default {
  components: { Modal },
  props: [
    "isActiveButton",
    "list",
    "round",
    "option",
    "number",
    "isOpen",
    "handleClose",
  ],
  data() {
    return {
      isShow: false,
      numberData: null,
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) this.modalOpen();
    },
  },
  methods: {
    modalOpen() {
      if (this.isActiveButton) {
        this.numberData = this.number;
        this.isShow = true;
        console.log("Open", this.number);
        console.log("Open", this.numberData);
      }
    },
    modalClose() {
      this.isShow = false;
      this.numberData = null;
      this.handleClose();
      console.log("Close", this.numberData);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-dark-brown {
  background: $blue;
  border-radius: 12px;
}
</style>
