import { request } from "./api";
import store from "../store";

const sendRequest = (data) => {
  return request(data, true);
};

export async function getLottoGroup() {
  return await sendRequest({
    op: "betconfig.getGroupInfo",
  });
}

export async function getLottoList(lotteryGroupId) {
  return await sendRequest({
    op: "betconfig.getLotteryList",
    params: {
      lottery_group_id: lotteryGroupId,
    },
  });
}

export async function getLottoRoundList(lotteryId) {
  return await sendRequest({
    op: "betconfig.getLotteryRoundList",
    params: {
      lottery_oid: lotteryId,
    },
  });
}

export async function getLottoBetOptions(roundOid) {
  return await sendRequest({
    op: "betconfig.getLotteryBetOptions",
    params: {
      round_oid: roundOid,
    },
  });
}

export async function getBetConfigList(roundOid, betOptionId) {
  return await sendRequest({
    op: "betconfig.getBetConfigList",
    params: {
      round_oid: roundOid,
      bet_option_id: betOptionId,
    },
  });
}

export async function getNumsFinalBetConfig(roundOid, betOptionId) {
  return await sendRequest({
    op: "betconfig.getNumsFinalBetConfig",
    params: {
      round_oid: roundOid,
      bet_option_id: betOptionId,
    },
  });
}

export async function getNumConfigInfo(roundOid, betOptionId, number) {
  return await sendRequest({
    op: "betconfig.getNumConfigInfo",
    params: {
      round_oid: roundOid,
      bet_option_id: betOptionId,
      number: number,
    },
  });
}

export async function getFinalBetConfig(roundOid, betOptionId) {
  return await sendRequest({
    op: "betconfig.getFinalBetConfig",
    params: {
      round_oid: roundOid,
      bet_option_id: betOptionId,
    },
  });
}

export async function getBetConfigData(roundOid, betOptionId, uniqid) {
  return await sendRequest({
    op: "betconfig.getBetConfigData",
    params: {
      round_oid: roundOid,
      bet_option_id: betOptionId,
      uniqid: uniqid,
    },
  });
}
export async function addNewBetConfig(data) {
  const response = await sendRequest({
    op: "betconfig.addNewBetConfig",
    params: {
      round_oid: data.round_oid,
      bet_option_id: data.bet_option_id,
      numbers: data.numbers,
      status: data.status,
      totalPerCust: parseInt(data.totalPerCust),
      bet_limit: {
        minBet: parseInt(data.bet_limit.minBet),
        maxBet: parseInt(data.bet_limit.maxBet),
      },
      reward_limit: {
        maxTotalBet: parseInt(data.reward_limit.maxTotalBet),
        reward: parseFloat(data.reward_limit.reward),
      },
    },
  });
  if (response) {
    store.dispatch("responseOpen", {
      type: "success",
      title: "สำเร็จ",
      description: "บันทึกเลขอั้นเรียบร้อย",
    });
    return true;
  } else {
    return false;
  }
}

export async function updateBetConfig(data) {
  const response = await sendRequest({
    op: "betconfig.updateBetConfig",
    params: {
      betconfig_uniqid: data.betconfig_uniqid,
      round_oid: data.round_oid,
      bet_option_id: data.bet_option_id,
      numbers: data.numbers,
      status: data.status,
      totalPerCust: parseInt(data.totalPerCust),
      bet_limit: {
        minBet: parseInt(data.bet_limit.minBet),
        maxBet: parseInt(data.bet_limit.maxBet),
      },
      reward_limit: {
        maxTotalBet: parseInt(data.reward_limit.maxTotalBet),
        reward: parseFloat(data.reward_limit.reward),
      },
    },
  });
  if (response) {
    store.dispatch("responseOpen", {
      type: "success",
      title: "สำเร็จ",
      description: "แก้ไขเลขอั้นเรียบร้อย",
    });
    return true;
  } else {
    return false;
  }
}
