<template>
  <div class="row">
    <div class="col-6">
      <p class="my-1 text-start check-number-detail">{{ title }}</p>
    </div>
    <div class="col-6">
      <p class="my-1 text-end check-number-detail">
        {{ filters.number(description) }} บาท
      </p>
    </div>
  </div>
</template>
<script>
import filters from "../../filters";
export default {
  setup() {
    return {
      filters,
    };
  },
  props: ["title", "description"],
};
</script>
<style lang="scss" scoped>
.check-number-detail {
  font-size: 20px;
  margin: 8px 0;
}
</style>
