<template>
  <button class="btn btn-secondary text-white" @click="modalOpen">
    <img src="~@/assets/fa-clipboard-check.svg" />
    สรุปเลขอั้นทั้งหมด
  </button>

  <div
    ref="modal"
    class="modal fade in modal-summary-num"
    :class="{ show: showTable, 'd-block': showTable }"
    tabindex="-1"
    role="dialog"
    id="modal-summary-num"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="round && opt">
            สรุปเลขอั้น
            <span class="text-yellow"> {{ lotteryName }} </span> ประเภท
            <span class="text-yellow">{{ opt.name }}</span>
          </h5>
        </div>
        <div class="modal-body text-center">
          <div class="searchSection">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <select class="form-select" v-model="typeToFind">
                  <option value="1" selected>ตัวเลข</option>
                  <option value="2">กลุ่มตัวเลข</option>
                </select>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control searchNum"
                  type="text"
                  placeholder="ค้นหาเลข"
                  v-on:keyup="findNumber"
                  v-model="inputSearch"
                  maxlength="3"
                />
              </div>
            </div>
          </div>
          <br />
          <div class="tableSection tableFixHead">
            <table class="table table-hover table-bordered table-responsive">
              <thead class="thead" :style="theadStyle">
                <tr class="thead-tr">
                  <th
                    class="thead-th pointer"
                    style="width: 15%"
                    @click="sortByNumGroup"
                  >
                    กลุ่มตัวเลข <img src="~@/assets/fa-sort.svg" />
                  </th>
                  <th
                    class="thead-th pointer"
                    style="width: 15%"
                    @click="sortByNum"
                  >
                    เลข <img src="~@/assets/fa-sort.svg" />
                  </th>
                  <th class="thead-th" style="width: 20%">สูงสุดต่อครั้ง</th>
                  <th class="thead-th" style="width: 20%">มากสุดต่อคน</th>
                  <th class="thead-th" style="width: 15%">บาทละ</th>
                  <th class="thead-th" style="width: 15%">รับสูงสุด</th>
                </tr>
              </thead>
              <tbody class="tbody">
                <tr
                  class="tbody-tr"
                  v-for="(summaryData, index) in summaryResult"
                  v-bind:key="index"
                >
                  <td class="tbody-td">{{ summaryData.number_group }}</td>
                  <td class="tbody-td bg-table-gray">
                    <div
                      @click="checkNumberOpen(summaryData.main_number)"
                      class="link-check-number"
                    >
                      {{ summaryData.main_number }}
                    </div>
                  </td>
                  <td
                    class="tbody-td red"
                    v-if="
                      summaryData.reward_step[0].reward === 0 &&
                      summaryData.reward_step[1].reward -
                        summaryData.reward_step[0].reward ===
                        0
                    "
                    colspan="4"
                  >
                    ปิด
                  </td>
                  <td
                    class="tbody-td"
                    v-if="
                      summaryData.reward_step[0].reward != 0 &&
                      summaryData.reward_step[1].reward -
                        summaryData.reward_step[0].reward !=
                        0
                    "
                  >
                    {{ filters.number(summaryData.bet_max) }}
                  </td>
                  <td
                    class="tbody-td bg-table-gray"
                    v-if="
                      summaryData.reward_step[0].reward != 0 &&
                      summaryData.reward_step[1].reward -
                        summaryData.reward_step[0].reward !=
                        0
                    "
                  >
                    {{ filters.number(summaryData.max_per_number) }}
                  </td>
                  <td
                    class="tbody-td"
                    v-if="
                      summaryData.reward_step[0].reward != 0 &&
                      summaryData.reward_step[1].reward -
                        summaryData.reward_step[0].reward !=
                        0
                    "
                  >
                    {{ filters.number(summaryData.reward_step[0].reward) }}
                  </td>
                  <td
                    class="tbody-td bg-table-gray"
                    v-if="
                      summaryData.reward_step[0].reward != 0 &&
                      summaryData.reward_step[1].reward -
                        summaryData.reward_step[0].reward !=
                        0
                    "
                  >
                    {{
                      filters.number(
                        summaryData.reward_step[
                          summaryData.reward_step.length - 1
                        ].amt
                      )
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="hasSlots" :class="css.tfoot">
                <tr :class="css.tfootTr">
                  <th :colspan="headers.length" :class="css.tfootTd">
                    <div :class="css.footer">
                      <slot name="ItemsPerPage" />
                      <slot name="pagination" />
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <br />
          <div class="buttonSection">
            <div class="d-float float-start">
              <button class="btn btn-success mx-2" type="button">
                Download&nbsp;&nbsp;&nbsp;<img
                  src="~@/assets/fa-download.svg"
                />
              </button>
            </div>
            <div class="d-float float-end">
              <button class="btn btn-secondary mx-2" @click="modalClose">
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showTable"
    class="modal-backdrop fade show"
    @click="modalClose"
  ></div>
</template>

<script>
import * as DashboardApi from "../../utils/dashboardApi";
import filters from "../../filters";

export default {
  setup() {
    return {
      filters,
    };
  },
  name: "Modal",
  props: {
    round: {
      required: true,
    },
    opt: {
      required: true,
    },
    handleCheckNumberOpen: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      summaryTable: null,
      summaryResult: null,
      sortByNumber: false, //0=asc 1=desc
      sortByGroup: false,
      showTable: false,
      typeToFind: 1, //1=number 2=group_number
      inputSearch: "",
      chkMainNumber: false,
    };
  },
  watch: {
    async showTable(newVal) {
      const body = document.getElementById("modal-summary-num");
      if (newVal) {
        body.classList.add("modal-open");
        this.summaryTable = await DashboardApi.getNumsFinalBetConfig(
          this.round._id,
          this.opt.id
        );
        this.summaryResult = this.summaryTable;
        this.chkMainNumber = false;
        this.inputSearch = "";
        this.typeToFind = 1;
        this.sortByNumber = false;
        this.sortByGroup = false;
        this.sortByNum();
      } else {
        this.summaryTable = null;
        body.classList.add("modal-close");
      }
    },
    typeToFind() {
      this.inputSearch = "";
      this.summaryResult = this.summaryTable;
      this.inputSearch = "";
      this.sortByNumber = false;
      this.sortByGroup = false;
      this.chkMainNumber = false;
    },
  },
  computed: {
    lotteryName() {
      return this.round ? this.round.lottery_name : null;
    },
    roundName() {
      return this.round ? this.round.round_name : null;
    },
    optName() {
      return this.opt ? this.opt.name : null;
    },
  },
  methods: {
    modalOpen() {
      this.showTable = true;
    },
    modalClose() {
      this.showTable = false;
    },
    sortByNumGroup() {
      let sortArr = [];

      if (this.chkMainNumber) {
        sortArr = this.summaryResult;
      } else {
        const keyArr = Object.keys(this.summaryResult);

        keyArr.map((ele, index) => {
          sortArr[index] = this.summaryResult[ele];
          sortArr[index]["main_number"] = ele;
        });
      }
      
      if (!this.sortByGroup) {
        const sortedArr = sortArr.sort(
          (a, b) => a["number_group"] - b["number_group"]
        );
        const obj = {};
        sortedArr.map((ele) => {
          obj[ele.main_number] = ele;
        });

        this.summaryResult = sortedArr;
        this.sortByGroup = true;
        this.chkMainNumber = true;
      } else {
        const sortedArr = sortArr.sort(
          (a, b) => b["number_group"] - a["number_group"]
        );
        const obj = {};
        sortedArr.map((ele) => {
          obj[ele.main_number] = ele;
        });

        this.summaryResult = sortedArr;
        this.sortByGroup = false;
        this.chkMainNumber = true;
      }
    },
    sortByNum() {
      let sortArr = [];

      if (this.chkMainNumber) {
        sortArr = this.summaryResult;
      } else {
        const keyArr = Object.keys(this.summaryResult);

        keyArr.map((ele, index) => {
          sortArr[index] = this.summaryResult[ele];
          sortArr[index]["main_number"] = ele;
        });
      }

      if (!this.sortByNumber) {
        const sortedArr = sortArr.sort(
          (a, b) => a["main_number"] - b["main_number"]
        );
        const obj = {};
        sortedArr.map((ele) => {
          obj[ele.main_number] = ele;
        });

        this.summaryResult = sortedArr;
        this.sortByNumber = true;
        this.chkMainNumber = true;
      } else {
        const sortedArr = sortArr.sort(
          (a, b) => b["main_number"] - a["main_number"]
        );
        const obj = {};
        sortedArr.map((ele) => {
          obj[ele.main_number] = ele;
        });

        this.summaryResult = sortedArr;
        this.sortByNumber = false;
        this.chkMainNumber = true;
      }
    },
    findNumber(event) {
      const srcArr = [];
      let foundArr = [];
      const keyArr = Object.keys(this.summaryTable);

      keyArr.map((ele, index) => {
        srcArr[index] = this.summaryTable[ele];
        srcArr[index]["main_number"] = ele;
      });

      if (this.typeToFind == 2) {
        const target = event.target.value
          .split("")
          .sort((a, b) => a - b)
          .join("");
        if (target != "") {
          const regex = new RegExp("(" + target + ")+");
          foundArr = srcArr.filter((element) =>
            element["number_group"].match(regex)
          );
          this.chkMainNumber = true;
        } else {
          foundArr = this.summaryTable;
          this.chkMainNumber = false;
        }
      } else if (this.typeToFind == 1) {
        if (event.target.value != "") {
          const regex = new RegExp("(" + event.target.value + ")+");
          foundArr = srcArr.filter((element) =>
            element["main_number"].match(regex)
          );
          this.chkMainNumber = true;
        } else {
          foundArr = this.summaryTable;
          this.chkMainNumber = false;
        }
      }
      this.summaryResult = foundArr;
    },
    checkNumberOpen(number) {
      this.handleCheckNumberOpen(number);
      this.modalClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  background-color: #2a40ff;
  color: #ffffff;
  text-align: center;
}

.btn-success {
  color: #ffffff;
}

.btn-success:hover {
  color: #ffffff;
}

.text-yellow {
  color: #f5f944;
}

.red{
  background-color: #FFD0D0;
  color: #FF2020;
}

.thead {
  background-color: #858585;
}

table tr:first-child th:first-child {
  border-top-left-radius: 18.91px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 18.91px;
}

.tableSection .thead .thead-tr {
  position: -webkit-sticky;
  position: sticky;
  background-color: #858585;
  top: -1px;
}

.tableSection {
  overflow: auto;
  max-height: calc(100vh - 450px);
}

.bg-table-gray {
  background-color: #f0f0f0;
}

.searchNum {
  left: 1041px;
  top: 195px;

  border-radius: 12.7323px;
}

.searchNum::placeholder {
  left: 77.56%;
  right: 16.89%;
  top: 22.37%;
  bottom: 74.34%;

  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.pointer {
  cursor: pointer;
}

.modal-summary-num {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}

.modal-content {
  margin: auto;
  z-index: 9999;
  width: 100%;
}
.link-check-number {
  cursor: pointer;
  &:hover {
    color: #2a40ff;
  }
}
</style>
