<template>
  <component :is="layout">
    <router-view />
    <Response />
    <div class="loading" :class="isLoading">
      <div class="d-flex justify-content-center align-items-center w-100 h-100">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </component>
</template>
<script>
import Response from "@/components/Response";
export default {
  name: "App",
  components: { Response },
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    },
    isLoading() {
      if (this.$store.getters.isLoading) {
        return null;
      } else {
        return "d-none";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  .spinner-border {
    width: 120px;
    height: 120px;
    color: $white;
  }
}
</style>
