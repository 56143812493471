export default {
  state: {
    isShow: false,
    type: null,
    title: null,
    description: null,
  },
  getters: {
    responseShow(state) {
      return state.isShow;
    },
    responseType(state) {
      return state.type;
    },
    responseTitle(state) {
      return state.title;
    },
    responseDescription(state) {
      return state.description;
    },
  },
  mutations: {
    openModal(state, { type, title, description }) {
      state.isShow = true;
      state.type = type;
      state.title = title;
      state.description = description;
    },
    closeModal(state) {
      state.isShow = false;
    },
  },
  actions: {
    responseOpen: ({ commit }, data) => {
      commit("openModal", data);
    },
    responseClose: ({ commit }) => {
      commit("closeModal");
    },
  },
};
