<template>
  <div class="homePage">
    <div class="container">
      <div class="row">
        <div class="col-3">
          <LottoGroupVue
            :groups="LottoGroup"
            v-on:onGroupSelect="onGroupSelect($event)"
          />
        </div>
        <div class="col-3">
          <LottoListVue
            :lists="LottoList"
            :disabled="ListDisabled"
            v-on:onListSelect="onListSelect($event)"
          />
        </div>
        <div class="col-3">
          <LottoRoundVue
            :rounds="LottoRound"
            :disabled="RoundDisabled"
            v-on:onRoundSelect="onRoundSelect($event)"
          />
        </div>
        <div class="col-3">
          <LottoBetVue
            :opts="LottoOpt"
            :disabled="OptDisabled"
            v-on:onOptionSelect="onOptionSelect($event)"
          />
        </div>
      </div>
      <div v-if="showTable === 'ok'">
        <div class="tableSection">
          <BetConfigTable
            :betConfig="LottoTable"
            :list="List"
            :round="Round"
            :option="Opt"
            :handleAction="getBetConfigList"
            :handleCheckNumberOpen="handleCheckNumberOpen"
          />
        </div>
        <div class="buttonSection">
          <div class="btn-section-item">
            <CheckNumber
              :isActiveButton="showTable"
              :list="List"
              :round="Round"
              :option="Opt"
              :number="checkNumber"
              :isOpen="isCheckNumberOpen"
              :handleClose="handleCheckNumberClose"
              
            />
          </div>
          <div class="btn-section-item">
            <CreateNumber
              :isActiveButton="showTable"
              :list="List"
              :round="Round"
              :option="Opt"
              :handleAction="getBetConfigList"
            />
          </div>
          <div class="btn-section-item">
            <SummaryModal
              :summaryModalShow="summaryModalShow"
              :handleCheckNumberOpen="handleCheckNumberOpen"
              :round="Round"
              :opt="Opt"
            />
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>
<script>
//---Components---//
import LottoGroupVue from "../components/Body/LottoGroup.vue";
import LottoListVue from "../components/Body/LottoList.vue";
import LottoRoundVue from "../components/Body/LottoRound.vue";
import LottoBetVue from "../components/Body/LottoBetOption.vue";
import BetConfigTable from "../components/Table/_BetConfigTable.vue";
import SummaryModal from "../components/Modals/SummaryModal.vue";
import CreateNumber from "../components/ManageNumberForm/Create.vue";
import CheckNumber from "../components/CheckNumber/index.vue";

//---Data---//
import * as DashboardApi from "../utils/dashboardApi";

export default {
  components: {
    LottoGroupVue,
    LottoListVue,
    LottoRoundVue,
    LottoBetVue,
    BetConfigTable,
    SummaryModal,
    CreateNumber,
    CheckNumber,
  },
  data() {
    return {
      LottoGroup: null,
      LottoList: null,
      LottoRound: null,
      LottoOpt: null,
      Group: null,
      ListDisabled: true,
      List: null,
      RoundDisabled: true,
      Round: null,
      OptDisabled: true,
      Opt: null,
      showTable: false,
      LottoTable: null,
      checkNumber: null,
      isCheckNumberOpen: false,
    };
  },
  async mounted() {
    const lottoGroup = await DashboardApi.getLottoGroup();
    this.LottoGroup = lottoGroup;
  },
  name: "Lotto",
  watch: {
    LottoGroup() {
      this.ListDisabled = true;
      this.LottoList = null;
      this.RoundDisabled = true;
      this.LottoRound = null;
      this.OptDisabled = true;
      this.LottoOpt = null;
      this.showTable = false;
    },
    LottoList() {
      this.RoundDisabled = true;
      this.LottoRound = null;
      this.OptDisabled = true;
      this.LottoOpt = null;
      this.showTable = false;
    },
    LottoRound() {
      this.OptDisabled = true;
      this.LottoOpt = null;
      this.showTable = false;
    },
    LottoOpt() {
      this.showTable = true;
    },
  },
  methods: {
    async onGroupSelect(value) {
      this.Group = value;
      if (this.Group) {
        const lottoList = await DashboardApi.getLottoList(this.Group);
        this.LottoList = lottoList;
        this.ListDisabled = false;
      }
    },
    async onListSelect(value) {
      this.List = value;
      if (this.List != "") {
        const lottoRound = await DashboardApi.getLottoRoundList(this.List._id);
        this.LottoRound = lottoRound;
        this.RoundDisabled = false;
      }
    },
    async onRoundSelect(value) {
      this.Round = value;
      if (this.Round != "") {
        const lottoOpt = await DashboardApi.getLottoBetOptions(this.Round._id);
        this.LottoOpt = lottoOpt;
        this.OptDisabled = false;
      }
    },
    onOptionSelect(value) {
      this.Opt = value;
      this.getBetConfigList();
    },
    async getBetConfigList() {
      if (
        this.Group != "" &&
        this.List._id != "" &&
        this.Round._id != "" &&
        this.Opt.id != ""
      ) {
        const lottoTable = await DashboardApi.getBetConfigList(
          this.Round._id,
          this.Opt.id
        );
        this.LottoTable = lottoTable;
        this.showTable = "ok";
      }
    },
    handleCheckNumberOpen(number) {
      this.checkNumber = number;
      this.isCheckNumberOpen = true;
    },
    handleCheckNumberClose() {
      this.checkNumber = null;
      this.isCheckNumberOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin-top: 35px;
}

.tableSection {
  margin-top: 20px;
  margin-bottom: 30px;
}

.buttonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  .btn-section-item {
    margin: 0 15px;
  }
}

.textWhite {
  color: #f8f8ff;
}

.textWhite:hover {
  color: #f8f8ff;
}

.thead-style {
  background-color: $primary;
  color: #f8f8ff;
}

.btn-ligth-blue {
  left: 0%;
  right: 58.38%;
  top: 0%;
  bottom: 0%;
  border-radius: 12.4754px;

  background-color: #00b1e9 !important;
  border-color: #00b1e9 !important;
}

.btn-ligth-green {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #33d519 !important;
  border-color: #33d519 !important;
  border-radius: 12.4754px;
}

.btn-dark-brown {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #565656;
  border-radius: 12.4754px;
}

.modal-form-number {
  .modal-content {
    border-radius: 25px;
    .modal-header {
      border-radius: 25px 25px 0 0;
      color: $white;
      background-color: $primary;
    }
    .input-number {
      width: 100px;
    }
  }
}
</style>
