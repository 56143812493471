import { createStore } from "vuex";
import response from "./response";
import auth from "./auth";
import loading from "./loading";
export default createStore({
  modules: {
    auth,
    response,
    loading,
  },
});
