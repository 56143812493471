import * as userApi from "../utils/authApi";
import router from "../router";

export default {
  state: {
    token: localStorage.getItem("token") || null,
    isLogin: localStorage.getItem("isLogin") || false,
    displayName: localStorage.getItem("displayName") || null,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getIsLogin(state) {
      return state.isLogin;
    },
    getDisplayName(state) {
      return state.displayName;
    },
  },
  mutations: {
    setToken(state, { token, displayName }) {
      localStorage.setItem("token", token);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("displayName", displayName);
      state.token = token;
      state.isLogin = true;
      state.displayName = displayName;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async login({ dispatch, commit }, payload) {
      const { status, token } = await userApi.login(Object.assign({}, payload));
      if (status === true) {
        dispatch("responseOpen", {
          type: "success",
          title: "Success",
          description: "Login Completed.",
        });
        if (token) {
          commit("setToken", {
            token: token,
            displayName: payload.username,
          });
          return true;
        } else {
          return false;
        }
      } else {
        dispatch("responseOpen", {
          type: "error",
          title: "Login Error",
          description: status,
        });
        return false;
      }
    },
    logout({ dispatch }) {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("displayName");
      dispatch("responseOpen", {
        type: "success",
        title: "Success",
        description: "Logout Completed.",
      });
      router.push("/login");
    },
  },
};
