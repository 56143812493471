<template>
  <div class="container">
    <DataTable
      :header-fields="headerFields"
      :sort-field="sortField"
      :sort="sort"
      :configArr="betConfigArr || []"
      :is-loading="isLoading"
      :css="datatableCss"
      :list="list"
      :round="round"
      :option="option"
      :handleAction="handleAction"
      :handleCheckNumberOpen="handleCheckNumberOpen"
      not-found-msg="Items not found"
      @on-update="dtUpdateSort"
      track-by="id"
    >
      <!-- Action button slot -->
      <button
        name="actions"
        type="button"
        class="btn btn-info"
        value="Edit"
        @click="dtEditClick(props)"
      ></button>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "./_DataTable.vue";
// import orderBy from "lodash.orderby";

const formatRRStep = (value) => {
  if (value) return "";
};
const editId = (value) => {
  if (value) {
    return value;
  }
};

export default {
  name: "BetConfigTable",
  components: {
    DataTable,
  },
  props: {
    betConfig: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    handleAction: {
      type: Function,
      required: true,
    },
    handleCheckNumberOpen: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.dtCheckLoop();
  },
  data: function () {
    return {
      headerFields: [
        // '__slot:checkboxes',
        {
          name: "id",
          label: "ลำดับ",
          sortable: true,
          width: "8%",
        },
        {
          name: "nums",
          label: "เลข",
          sortable: true,
          width: "24%",
        },
        {
          name: "bet_range",
          label: "ช่วงราคาที่แทงได้",
          width: "15%",
          //   customElement: 'HometownNew'
        },
        {
          name: "maxBetPerCust",
          label: "มากสุดต่อคน",
          width: "12%",
        },
        {
          name: "reward_step",
          label: "จ่ายรางวัล",
          width: "20%",
          //   customHeader: true,
          test: formatRRStep,
        },
        {
          name: "status",
          label: "สถานะ",
          //   customHeader: true,
          sortable: true,
          //   format: formatDate
          width: "8%",
        },
        {
          name: "id",
          label: "",
          //   customHeader: true,
          //   sortable: true,
          edit: editId,
          width: "8%",
        },
        // '__slot:actions'
      ],
      betConfigArr: null,
      datatableCss: {
        table: "table table-bordered border-white table-center table-style",
        thead: "thead",
        theadTr: "header-item",
        theadTh: "custom-thead-th",
        thWrapper: "th-wrapper",
        thWrapperCheckboxes: "th-wrapper checkboxes",
        arrowsWrapper: "arrows-wrapper",
        arrowUp: "arrow up",
        arrowDown: "arrow down",
        footer: "footer",
      },
      paginationCss: {
        paginationItem: "pagination-item",
        moveFirstPage: "move-first-page",
        movePreviousPage: "move-previous-page",
        moveNextPage: "move-next-page",
        moveLastPage: "move-last-page",
        pageBtn: "page-btn",
      },
      isLoading: false,
      sort: "asc",
      sortField: "id",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 16,
      //   hometown: null
    };
  },
  methods: {
    dtEditClick: (props) => alert(`Click props: ${JSON.stringify(props)}`),

    // dtUpdateSort: function({ sortField, sort }) {
    //   const sortedData = orderBy(data, [sortField], [sort]);
    //   const start = (this.currentPage - 1) * this.itemsPerPage;
    //   const end = this.currentPage * this.itemsPerPage;
    //   this.data = sortedData.slice(start, end);
    // },

    // updateItemsPerPage: function(itemsPerPage) {
    //   this.itemsPerPage = itemsPerPage;
    //   if (itemsPerPage >= data.length) {
    //     this.data = data;
    //   } else {
    //     this.data = data.slice(0, itemsPerPage);
    //   }
    // },

    // changePage: function(currentPage) {
    //   this.currentPage = currentPage;
    //   const start = (currentPage - 1) * this.itemsPerPage;
    //   const end = currentPage * this.itemsPerPage;
    //   this.data = data.slice(start, end);
    // },

    updateCurrentPage: function (currentPage) {
      this.currentPage = currentPage;
    },

    actionFirstClick: (params) => {
      alert(JSON.stringify(params));
    },

    dtCheckLoop: function () {
      const tableArr = new Array();
      this.betConfig.map((ele) => {
        tableArr.push(ele);
      });
      this.betConfigArr = tableArr;
    },
  },
  watch: {
    betConfig: function () {
      this.dtCheckLoop();
    },
  },
};
</script>
