<template>
  <div>
    <button
      class="btn-manage-number-update"
      @click="modalOpen"
      :disabled="!isActiveButton"
    >
      แก้ไข
    </button>
    <div
      ref="modal"
      class="modal fade in modal-form-number"
      :class="{ show: isShow, 'd-block': isShow }"
      tabindex="-1"
      role="dialog"
      :id="modalId"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <Title
                :listName="listName"
                :roundName="roundName"
                :optionName="optionName"
              />
            </h5>
            <StatusInput
              :defaultData="form.status"
              :handleAction="handleStatus"
            />
          </div>
          <div class="modal-body text-center">
            <div class="row">
              <div class="col-6">
                <GenerateNumber
                  :optionDigit="optionDigit"
                  :handleNumberList="handleNumberList"
                />
                <NumberList
                  :betNumber="form.numbers"
                  :replaceNumberList="replaceNumberList"
                  :isErrorText="errors.numbers"
                />
              </div>
              <div class="col-6">
                <TotalPerCust
                  :placeHolder="defaultData.totalPerCust ?? null"
                  :defaultData="form.totalPerCust"
                  :handleAction="handleDataTotalPerCust"
                  :handleError="handleError"
                />
                <MinBet
                  :placeHolder="defaultData.bet_limit.minBet ?? null"
                  :defaultData="form.bet_limit.minBet"
                  :handleAction="handleDataMinBet"
                  :handleError="handleError"
                />
                <MaxBet
                  :placeHolder="defaultData.bet_limit.maxBet ?? null"
                  :defaultData="form.bet_limit.maxBet"
                  :handleAction="handleDataMaxBet"
                  :handleError="handleError"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <MaxTotalBet
                  :defaultData="form.reward_limit.maxTotalBet"
                  :handleAction="handleDataMaxTotalBet"
                  :handleError="handleError"
                />
              </div>
              <div class="col-6">
                <Reward
                  :defaultData="form.reward_limit.reward"
                  :handleAction="handleDataReward"
                  :handleError="handleError"
                />
              </div>
            </div>
            <div class="d-float float-end">
              <button class="btn btn-secondary mx-2" @click="modalClose">
                ยกเลิก
              </button>
              <button
                class="btn btn-success text-white mx-2"
                :disabled="!isSubmit"
                @click="handleCreateNumber()"
              >
                <img src="~@/assets/save-icon.svg" class="me-2" />
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShow" class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
import * as DashboardApi from "@/utils/dashboardApi.js";
import Title from "./Title.vue";
import StatusInput from "./StatusInput.vue";
import GenerateNumber from "./GenerateNumber.vue";
import NumberList from "./NumberList.vue";
import TotalPerCust from "./TotalPerCust.vue";
import MinBet from "./MinBet.vue";
import MaxBet from "./MaxBet.vue";
import MaxTotalBet from "./MaxTotalBet.vue";
import Reward from "./Reward.vue";

export default {
  components: {
    Title,
    StatusInput,
    GenerateNumber,
    NumberList,
    TotalPerCust,
    MinBet,
    MaxBet,
    MaxTotalBet,
    Reward,
  },
  props: {
    isActiveButton: {
      type: Boolean,
      required: false,
    },
    list: {
      type: Array,
      required: true,
    },
    round: {
      type: Boolean,
      required: true,
    },
    option: {
      type: Array,
      required: true,
    },
    uniqid: {
      type: String,
      default: null,
    },
    handleData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
      numberOptions: {},
      defaultData: {
        totalPerCust: null,
        bet_limit: {
          minBet: null,
          maxBet: null,
        },
        reward_limit: {
          maxTotalBet: null,
          reward: null,
        },
        status: null,
      },
      form: {
        betconfig_uniqid: null,
        round_oid: null,
        bet_option_id: null,
        numbers: {},
        status: null,
        totalPerCust: null,
        bet_limit: {
          minBet: null,
          maxBet: null,
        },
        reward_limit: {
          maxTotalBet: null,
          reward: null,
        },
      },
      errors: {
        numbers: false,
        totalPerCust: false,
        minBet: false,
        maxBet: false,
        maxTotalBet: false,
        reward: false,
      },
    };
  },
  mounted() {
    this.resetData();
  },
  watch: {
    isShow(newVal) {
      const body = document.getElementById(this.modalId);
      if (newVal) {
        body.classList.add("modal-open");
        this.form.round_oid = this.round._id;
        this.form.bet_option_id = this.option.id;
        this.form.betconfig_uniqid = this.uniqid;
      } else {
        body.classList.remove("modal-open");
      }
    },
  },
  computed: {
    listName() {
      return this.list ? this.list.name : null;
    },
    roundName() {
      return this.round ? this.round.round_name : null;
    },
    optionName() {
      return this.option ? this.option.name : null;
    },
    optionDigit() {
      return this.option ? this.option.digit : 0;
    },
    modalId() {
      if (this.uniqid) {
        return `modal-form-number-${this.uniqid}`;
      } else {
        return "modal-form-number-update";
      }
    },
    isSubmit() {
      const result = !(
        this.errors.numbers ||
        this.errors.totalPerCust ||
        this.errors.minBet ||
        this.errors.maxBet ||
        this.errors.maxTotalBet ||
        this.errors.reward
      );
      return result;
    },
  },
  methods: {
    modalOpen() {
      if (this.isActiveButton) {
        this.isShow = true;
        this.handleGetDefaultNumber();
        this.resetData();
      }
    },
    modalClose() {
      this.isShow = false;
      this.resetData();
    },
    resetData() {
      this.form = {
        numbers: {},
        status: null,
        totalPerCust: null,
        bet_limit: {
          minBet: null,
          maxBet: null,
        },
        reward_limit: {
          maxTotalBet: null,
          reward: null,
        },
      };
    },
    handleNumberList(addNumber) {
      let betNumber = Object.values(this.form.numbers);
      betNumber = betNumber.filter((el) => {
        return addNumber.indexOf(el) < 0;
      });
      for (let i = 0; i < addNumber.length; i++) {
        betNumber.unshift(addNumber[i]);
      }
      this.form.numbers = betNumber;
      this.handleValidateNumber();
    },
    replaceNumberList(betNumber) {
      this.form.numbers = betNumber;
      this.handleValidateNumber();
    },
    async handleGetDefaultNumber() {
      const currentData = await DashboardApi.getBetConfigData(
        this.round._id,
        this.option.id,
        this.uniqid
      );
      const defaultData = await DashboardApi.getFinalBetConfig(
        this.round._id,
        this.option.id
      );
      if (currentData) {
        this.defaultData = defaultData;
        this.form.numbers = currentData.numbers;
        this.form.status = currentData.status;
        this.form.totalPerCust = currentData.totalPerCust;
        this.form.bet_limit.minBet = currentData.bet_limit.minBet;
        this.form.bet_limit.maxBet = currentData.bet_limit.maxBet;
        this.form.reward_limit.maxTotalBet =
          currentData.reward_limit.maxTotalBet;
        this.form.reward_limit.reward = currentData.reward_limit.reward;
      } else {
        this.modalClose();
      }
    },
    async handleCreateNumber() {
      await this.handleValidateNumber();
      if (this.isSubmit) {
        const result = await DashboardApi.updateBetConfig(this.form);
        if (result) {
          this.handleData();
          this.modalClose();
        }
      }
    },
    handleStatus(data) {
      this.form.status = data;
    },
    handleDataTotalPerCust(data) {
      this.form.totalPerCust = data;
    },
    handleDataMinBet(data) {
      this.form.bet_limit.minBet = data;
    },
    handleDataMaxBet(data) {
      this.form.bet_limit.maxBet = data;
    },
    handleDataMaxTotalBet(data) {
      this.form.reward_limit.maxTotalBet = data;
    },
    handleDataReward(data) {
      this.form.reward_limit.reward = data;
    },
    handleError(data, type) {
      this.errors[type] = data;
    },
    handleValidateNumber() {
      if (
        this.form.numbers.length === 0 ||
        this.form.numbers.length === undefined
      ) {
        this.errors.numbers = true;
      } else {
        this.errors.numbers = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-manage-number-update {
  background: #FFFFFF;
  border-radius: 12px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 20px;
  box-shadow: (0px 4px 10px rgba(0, 0, 0, 0.2));
}
.modal-form-number {
  font-size: 16px;
  .modal-content {
    border-radius: 25px;
    .modal-header {
      border-radius: 25px 25px 0 0;
      color: $white;
      background-color: $primary;
    }
    .input-number {
      width: 100px;
    }
  }
}
.disabled {
  cursor: not-allowed;
}
</style>
