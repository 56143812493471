<template>
  <table class="table-check-number">
    <thead>
      <tr>
        <td><p>เงื่อนไข</p></td>
        <td><p>อัตราจ่ายบาทละ</p></td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(data, index) in dataRewards"
        :key="index"
        :class="currentBet(index)"
      >
        <td>
          <p>มากกว่า {{ filters.number(data.amt) }}</p>
        </td>
        <td>
          <p :class="hilightReward(index)">{{ textReward(data.reward) }}</p>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import filters from "../../filters";

export default {
  setup() {
    return {
      filters,
    };
  },
  props: ["dataRewards", "totalBet"],
  methods: {
    hilightReward(index) {
      return this.dataRewards[index].reward === 0 ||
        this.currentBet(index) === "current-reward"
        ? "text-danger"
        : "";
    },
    textReward(data) {
      return data === 0 ? "ปิด" : data;
    },
    currentBet(index) {
      if (this.dataRewards[index].amt >= this.totalBet) {
        if (this.dataRewards[index - 1]) {
          if (this.dataRewards[index - 1].amt < this.totalBet) {
            return "current-reward";
          }
        } else {
          return "current-reward";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-check-number {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  overflow: hidden;
  border: 4px solid #929292;
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border-spacing: 0;
  thead {
    color: $white;
    background-color: #929292;
    td {
      padding: 6px;
      p {
        margin: 10px 0;
        font-size: 22px;
      }
    }
  }
  tbody {
    tr {
      &.current-reward {
        background-color: #d2ffcb;
      }
      td {
        p {
          margin: 10px 0;
          font-size: 22px;
        }
        .text-danger {
          position: relative;
        }
      }
    }
  }
}
</style>
