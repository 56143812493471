<template>
  <div class="text-center">
    <p class="check-number-title">
      {{ listName }}
      {{ roundName }} / {{ optionName }}
    </p>
  </div>
</template>
<script>
export default {
  props: ["listName", "roundName", "optionName"],
};
</script>
<style lang="scss" scoped>
.check-number-title {
  font-size: 30px;
  margin: 10px 0;
}
</style>
