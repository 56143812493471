<template>
  <div class="row g-3 align-items-center">
    <div class="col-1">
      <label for="inputNumber" class="col-form-label">เลข</label>
    </div>
    <div class="col-4">
      <input
        type="text"
        id="inputNumber"
        class="form-control input-number"
        aria-describedby="numberHelpInline"
        autocomplete="off"
        v-model="data"
        :class="{ 'is-invalid': errors }"
      />
    </div>
    <div class="col-4">
      <select
        class="form-select"
        aria-label="Default select example"
        v-model="option"
      >
        <option
          v-for="(data, index) in numberOptions"
          :key="index"
          :value="data.value"
        >
          {{ data.title }}
        </option>
      </select>
    </div>
    <div class="col-3">
      <button
        class="btn btn-success text-white"
        :disabled="!isActive"
        @click="handleClick"
      >
        เพิ่ม
      </button>
    </div>
  </div>
  <div class="row mb-4">
    <div class="offset-1 col-11 text-start  mb-2">
      <span v-if="errors" role="alert" class="text-danger text-start">
        <strong>{{ errors }}</strong>
      </span>
    </div>
  </div>
</template>
<script>
import validate from "@/utils/validate";

export default {
  props: ["optionDigit", "handleNumberList", "countReset"],
  data() {
    return {
      data: null,
      option: 1,
      numberList: [],
      numberListTmp: [],
      countTest: 0,
    };
  },
  watch:{
    countReset() {
      this.option = 1;
      this.data = null;
    },
  },
  computed: {
    numberOptions() {
      let numberOptions = [];
      numberOptions.push({
        value: 1,
        title: "ตัวเดียว",
      });
      const allOption = this.getSplitOption();
      if (allOption) {
        numberOptions.push(allOption);
      }
      return numberOptions;
    },
    isActive() {
      if (this.data) {
        return (
          validate.length(this.data, this.optionDigit) &&
          validate.number(this.data)
        );
      } else {
        return false;
      }
    },
    errors() {
      if (this.isActive || !this.data) {
        return null;
      } else {
        return `ต้องใส่เป็นตัวเลข ${this.optionDigit} หลัก เท่านั้น`;
      }
    },
  },
  methods: {
    getSplitOption() {
      let number = 1;
      let unique = null;
      let uniqueLength = this.optionDigit;
      if (this.optionDigit === 3) {
        number = 6;
      } else if (this.optionDigit === 2) {
        number = 2;
      }

      if (this.data) {
        if (this.isActive && this.data.length === this.optionDigit) {
          let dataSplit = this.data.split("");
          unique = [...new Set(dataSplit)];
          uniqueLength = unique.length;
          if (uniqueLength === 1) {
            number = 1;
          } else if (this.optionDigit === 3) {
            number = 6;
          } else if (this.optionDigit === 2) {
            number = 2;
          }
        }
      }
      if (number > 1) {
        return {
          value: "all",
          title: `${number} กลับ`,
        };
      } else {
        this.option = 1;
        return null;
      }
    },
    async handleClick() {
      if (this.isActive) {
        let numberListUnique = null;
        if (this.option === 1) {
          numberListUnique = [this.data];
        } else {
          let dataSplit = this.data.split("");
          const numberList = await this.generate(dataSplit);
          numberListUnique = [...new Set(numberList)];
        }
        if (numberListUnique) {
          this.handleNumberList(numberListUnique);
        }
      }
    },
    generate(array) {
      let data = [];
      if (array.length === 3) {
        data.push(array[0] + array[1] + array[2]);
        data.push(array[0] + array[2] + array[1]);
        data.push(array[1] + array[0] + array[2]);
        data.push(array[1] + array[2] + array[0]);
        data.push(array[2] + array[0] + array[1]);
        data.push(array[2] + array[1] + array[0]);
      } else if (array.length === 2) {
        data.push(array[0] + array[1]);
        data.push(array[1] + array[0]);
      }
      return data;
    },
  },
};
</script>
