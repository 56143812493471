<template>
  <div>
    เพิ่มเลขอั้น <span class="text-yellow">{{ listName }}</span> รอบ
    <span class="text-yellow">{{ roundName }}</span> ประเภท
    <span class="text-yellow">{{ optionName }}</span>
  </div>
</template>
<script>
export default {
  props: ["listName", "roundName", "optionName"],
};
</script>
<style lang="scss" scoped>
.text-yellow {
  color: $yellow;
}
</style>
