<template>
  <select class="form-select" v-model="group" v-on:change="groupSelected">
    <option disabled value="" selected>เลือกประเภทหวย</option>
    <option v-for="(group, name) in groups" v-bind:key="group" :value="name">
      {{ group.name }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      group: null,
    };
  },
  name: "Group",
  methods: {
    groupSelected() {
      var groupSeleted = this.group;
      this.$emit("onGroupSelect", groupSeleted);
    },
  },
  props: {
    groups: {
      type: Object,
      required: true,
    },
  },
};
</script>
