<template>
  <div class="row g-3 align-items-center">
    <div class="col-5">
      <label for="inputMaxTotalBet" class="col-form-label"
        >รับแทงสูงสุด</label
      >
    </div>
    <div class="col-5">
      <input
        type="text"
        id="inputMaxTotalBet"
        class="form-control text-end"
        aria-describedby="maxTotalBetHelpInline"
        autocomplete="off"
        v-model="data"
        :class="{ 'is-invalid': errors }"
        :placeHolder="placeHolder"
      />
    </div>
    <div class="col-1">
      <span id="maxTotalBetHelpInline"> บาท </span>
    </div>
  </div>
  <div class="row">
    <div class="col-7 offset-5 text-start mb-2">
      <span v-if="errors" class="text-danger" role="alert">
        <strong>{{ errors }}</strong>
      </span>
    </div>
  </div>
</template>
<script>
import validate from "@/utils/validate";
export default {
  props: {
    placeHolder: {
      type: String,
      default: null,
    },
    defaultData: {
      type: String,
      default: null,
    },
    handleAction: { type: Function },
    handleError: { type: Function },
    countReset: { type: Number },
  },
  data() {
    return {
      data: null,
      errors: null,
    };
  },
  watch: {
    countReset() {
      this.errors = null;
      this.data = null;
    },
    defaultData(newVal) {
      if (newVal) {
        this.data = newVal;
      }
    },
    data(newVal) {
      if (!validate.required(newVal)) {
        this.errors = "จำเป็นต้องกรอก";
        this.handleError(true, "maxTotalBet");
      } else if (validate.price(newVal)) {
        this.errors = null;
        this.handleError(false, "maxTotalBet");
      } else {
        this.errors = "จำเป็นต้องใส่เป็นราคาเท่านั้น";
        this.handleError(true, "maxTotalBet");
      }
      this.handleAction(newVal);
    },
  },
};
</script>
